import { ReactNode, useLayoutEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { Routes, Route, HashRouter, useLocation } from "react-router-dom";

import "#i18n";
import "#styles/reset.scss";
import { theme } from "#styles/muiTheme";
import ToTop from "#components/ToTop/ToTop";
// import Header from "#components/Header/Header";
// import Footer from "#components/Footer/Footer";
import { IRouterType, routes } from "./routes";

const Wrapper = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

const getRoutes = (routes: IRouterType[]): IRouterType[] => {
  return getRoutePage(routes).flat(Infinity);
};
const getRoutePage = (routes: IRouterType[]) => {
  let routesList: any = [];
  if (routes.length > 0) {
    routesList = routes.map((route) => {
      if (route.children) {
        return getRoutePage(route.children);
      }
      return [route];
    });
  }
  return routesList;
};

const App = () => {
  const routesList = getRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <HashRouter basename="/">
        <Wrapper>
          {/* <Header /> */}
          <Routes>
            {routesList.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
          {/* <Footer /> */}
          <ToTop />
        </Wrapper>
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;
