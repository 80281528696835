import { Navigate } from "react-router-dom";

// import Homepage from "#pages/homepage";
// import AboutUs from "#pages/about-us";
import Games from "#pages/games";
import GameDetail from "#pages/games/gameDetail";
// import BackOffice from "#pages/back-office";
// import News from "#pages/news";
// import NewsDetail from "#pages/news/newsDetail";
// import Partners from "#pages/partners";
// import History from "#pages/history";
// import Contact from "#pages/contact-us";
// import PrivacyPolicy from "#pages/privacy-policy";

export interface IRouterType {
  path: string;
  element?: JSX.Element;
  label?: string;
  children?: IRouterType[];
}

/* 若無需顯示在NavBar則 label = undefined 
   若NavBar有多層顯示需求則將route放在children下
*/

export const routes: IRouterType[] = [
  { path: "/games", element: <Games />, label: "games" },
  {
    path: "/games/:gameName",
    element: <GameDetail />,
  },
  {
    path: "*",
    element: <Navigate to="/games" replace />,
  },
];
