import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Button, Tabs, Tab } from "@mui/material";

import styles from "./style.module.scss";
import { useGetGames, useTitle } from "#utils/hooks";
import {
  gameNameParser,
  booleanSortComparator,
  getLocale,
} from "#utils/helpers";

const TABS = [
  { label: "all" },
  { label: "isHot" },
  { label: "isNew" },
  { label: "isComing" },
  { label: "slot" },
  { label: "fishing" },
  // { label: "poker" },
  // { label: "competitive" },
  { label: "sicbo" },
  { label: "crash" },
] as const;

export const BONUS_IDENTIFIER = "-bonus";

const Games = () => {
  const { t, i18n: i18nI } = useTranslation();
  useTitle("common.title.games", t);
  const i18n = (key: string) => t(`page.games.${key}`);
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const { games } = useGetGames((games) =>
    games
      .sort((a, b) => booleanSortComparator(a, b, "isHot"))
      .sort((a, b) => booleanSortComparator(a, b, "isNew"))
      .sort((a, b) => booleanSortComparator(a, b, "isComing"))
  );
  const comingGame = useMemo(
    () => games.find((game) => game.isComing),
    [games]
  );

  return (
    <main className={styles.games}>
      {comingGame && (
        <section
          className={classNames(
            styles.games__section,
            styles["games__section--leftRight"]
          )}
        >
          <div className={styles.games__text}>
            <h2 className={styles.games__title}>
              {comingGame.gameInfo[getLocale(i18nI.language)].gameName}
            </h2>
            <p className={styles.games__releaseDate}>COMING SOON</p>
            <Button className={styles.games__button}>
              <Link
                to={`/games/${gameNameParser(comingGame.gameInfo.en.gameName)}`}
              >
                {i18n("jumbotron.view")}
              </Link>
            </Button>
          </div>
          <img src={comingGame.imgUrl} alt="" className={styles.games__image} />
        </section>
      )}
      <section className={styles.games__section}>
        <div className={styles.games__tabs}>
          <Tabs value={tab} onChange={(_, v) => setTab(v)}>
            {TABS.map((tab) => (
              <Tab key={tab.label} label={i18n(`tabs.${tab.label}`)} />
            ))}
          </Tabs>
        </div>
        <ul className={styles.games__list}>
          {games
            .filter((game) => {
              const tabLabel = TABS[tab].label;
              switch (tabLabel) {
                case "all":
                  return true;
                case "isHot":
                case "isNew":
                case "isComing":
                  return game[tabLabel];
                default:
                  return game.category === tabLabel;
              }
            })
            .map((game) => (
              <li key={game.imgUrl + game.playUrl}>
                <Link
                  className={
                    game.isComing
                      ? classNames(styles.games__list__item, styles.coming)
                      : game.isHot
                      ? classNames(styles.games__list__item, styles.hot)
                      : game.isNew
                      ? classNames(styles.games__list__item, styles.new)
                      : styles.games__list__item
                  }
                  to={{ pathname: `./${gameNameParser(game.gameInfo.en.gameName)}${
                    game.isBonus ? BONUS_IDENTIFIER : ""
                  }`, search: location.search }}
                >
                  <img
                    src={`${game.imgUrl}`}
                    alt={game.gameInfo[getLocale(i18nI.language)].gameName}
                  />
                </Link>
              </li>
            ))}
        </ul>
      </section>
    </main>
  );
};

export default Games;
